export var SearchList = [
  { labe: '订单编号', code: 'ddBh', type: 'input' },
  { labe: '物料编号', code: 'wlBh', type: 'input' }
]
export var SearchData = {
  ddBh: '',
  wlBh: ''
}

export var tableField = [
  { type: 'input', selectType: 'selection', width: '', reserveSelection: true },
  { label: '订单编号', code: 'orderCode', type: 'input', width: '30%' },
  { label: '物料编号', code: 'materialCode', type: 'input', width: '40%' },
  { label: '物料描述', code: 'materialName', type: 'input', width: '' },
  { label: '采购员', code: 'buyerName', type: 'input', width: '30%' }
]

export var tableData = []
