<template>
    <div class="roleTab clearfix">
      <list-search @onSearch="onSearch" @clearSearch="clearSearch" :childData="childData"></list-search>
      <global-table ref="libraryData" row-key="materialCode" @SelectionChange='SelectionChange' :tableField="tableField" :tableData="tableData" :paginationData="paginationData" @pageChange="pageChange"
    ></global-table>
    </div>
</template>

<script>
import ListSearch from '@/components/actionList/ListSearch.vue'
import GlobalTable from '@/components/actionList/GlobalTable.vue'
import { request } from '@/assets/js/http.js'
import { SearchList, SearchData, tableField, tableData } from './AddInOrderBody.js'
export default {
  components: { ListSearch, GlobalTable },
  name: 'AddInOrderBody',
  props: ['tabData', 'dataBox', 'type'],
  data () {
    var pageSize = parseInt((document.documentElement.clientHeight - 330) / 42)
    if (pageSize < 10) {
      pageSize = 10
    }
    return {
      tableField: tableField,
      tableData: tableData,
      pageNum: 1,
      paginationData: {
        pageSize: pageSize,
        total: 0
      },
      childData: { searchList: SearchList, searchData: SearchData }
    }
  },
  created () {
    this.myBidData()
  },
  mounted () {

  },
  methods: {
    // 获取订单入库数据列表
    myBidData () {
      var NewErpCode = this.dataBox.ruleSupplierNumber
      var NewStr = NewErpCode.lastIndexOf('-')
      NewErpCode = NewErpCode.substring(0, NewStr)
      const obj = {
        pageNum: this.pageNum,
        pageSize: this.paginationData.pageSize,
        privateCode: NewErpCode,
        materialCode: this.childData.searchData.wlBh,
        orderCode: this.childData.searchData.ddBh
      }
      if (this.type === '1') {
        request('/api/order/order/getOrderListByCodeOut', 'POST', obj).then((res) => {
          if (res.code === '200') {
            this.tableData = res.data.records
            if (this.paginationData.total !== res.data.total) {
              this.paginationData.total = res.data.total
            }
          }
        })
      } else if (this.type === '2') {
        request('/api/order/order/getOrderListByCodeDrawer', 'POST', obj).then((res) => {
          if (res.code === '200') {
            this.tableData = res.data.records
            if (this.paginationData.total !== res.data.total) {
              this.paginationData.total = res.data.total
            }
          }
        })
      } else if (this.type === '3') {
        request('/api/order/order/getOrderListByCodeGeneral', 'POST', obj).then((res) => {
          if (res.code === '200') {
            this.tableData = res.data.records
            if (this.paginationData.total !== res.data.total) {
              this.paginationData.total = res.data.total
            }
          }
        })
      }
    },
    // 获取选中的数据
    SelectionChange (data) {
      this.selectTabData = data
    },
    // 查询
    onSearch (data) {
      this.pageNum = 1
      this.myBidData()
    },
    // 清空
    clearSearch (data) {
      this.pageNum = 1
      this.myBidData()
    },
    // 分页
    pageChange (data) {
      this.pageNum = data
      this.myBidData()
    }
  },
  watch: {
  }
}
</script>
<style scoped>
  .tableNav{
    border: none;
  }
  .tableList{
    padding: 0 20px;
  }
  .roleTab .tableNav :deep(.searchList .col){
    min-width: 250px;
  }
</style>
